<template>
  <div>
    <div class="content-between">
      <div class="mr-sm-4 mb-0">
        <div class="header-tablepage">
          Remain Credit (Last Update :
          {{ $moment(credit.updatedTime).format("DD-MM-YYYY HH:mm:ss") }})
        </div>
        <span>Credit: {{ credit.credit }}</span>
      </div>
      <div>
        <b-button variant="custom-outline-primary" @click="$refs.Modal.open()"
          >Adjust</b-button
        >
        <b-button
          variant="custom-outline-primary"
          class="mx-2"
          @click="$refs.sidebarFilter.show()"
          >Filter</b-button
        >
        <b-button
          variant="custom-outline-primary"
          @click="exportFile"
          :disabled="disableBtnExport"
          >Export
        </b-button>
      </div>
    </div>
    <b-tabs v-model="filter.ChannelId" @input="getData">
      <b-tab title="SMS"></b-tab>
      <b-tab title="Line"></b-tab>
      <b-tab title="Email"></b-tab>
    </b-tabs>

    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(tagType)="{ item }">
            <span>
              {{ item.tagType ? item.tagType : "-" }}
            </span>
          </template>
          <template v-slot:cell(message)="{ item }">
            <span>
              {{ item.message ? item.message : "-" }}
            </span>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Modal
      ref="Modal"
      footerClass="content-between"
      @hidden="clearValue"
      @ok="adJustCredit"
      title="Adjust Credit"
    >
      <template #content>
        <InputSelect
          title="Credit Type"
          name="credit-type"
          isRequired
          :options="channelType"
          v-model="creditAdjust.MessageTypeId"
          valueField="value"
          textField="text"
        />
        <InputText
          textFloat="Credit"
          placeholder="add credit"
          type="number"
          name="add-credit"
          isRequired
          v-model="creditAdjust.credit"
          :v="$v.creditAdjust.credit"
          :isValidate="$v.creditAdjust.credit.$error"
          :oninput="'javascript: if (this.value.length >7) this.value = this.value.slice(0, 7);'"
        />
      </template>
    </Modal>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :hideStatusFilter="false"
      @clearFilter="clearFilter"
      :hideSearchBar="true"
      @searchAll="getData"
    >
      <template v-slot:filter-option>
        <InputSelect
          title="Month"
          name="Month"
          v-model="filter.Month"
          :options="month"
          valueField="value"
          textField="text"
        >
          <template v-slot:option-first>
            <b-form-select-option value="0" disabled
              >-- Select Month --
            </b-form-select-option>
          </template>
        </InputSelect>

        <InputSelect
          title="Year"
          name="Year"
          :options="year"
          v-model="filter.Year"
          valueField="value"
          textField="text"
        >
          <template v-slot:option-first>
            <b-form-select-option value="0" disabled>
              -- Select Year --
            </b-form-select-option>
          </template>
        </InputSelect>
      </template>
    </SideBarFilter>
    <FooterAction routePath="/setting" :hideSubmit="true" />
  </div>
</template>

<script>
import Modal from "utility/Modal";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  components: {
    Modal,
  },
  validations: {
    creditAdjust: {
      credit: {
        required,
      },
    },
  },
  data() {
    return {
      fields: [
        { key: "year", label: "Year" },
        { key: "month", label: "Month" },
        { key: "tagType", label: "Type" },
        { key: "message", label: "Message" },
        { key: "totalMessage", label: "Total Message" },
        { key: "credit", label: "Credit" },
      ],
      items: [],
      filter: {
        ChannelId: 0, // 1 = SMS , 2 = Line , 3 = email
        Month: 0,
        Year: 0,
      },
      creditAdjust: { MessageTypeId: 1, credit: "" },
      credit: {
        brandId: 0,
        messageTypeId: 0,
        messageType: "",
        credit: "",
        updatedTime: "",
      },
      isBusy: false,
      disableBtnExport: false,
      channelType: [
        {
          text: "SMS",
          value: 1,
        },

        {
          text: "Line",
          value: 2,
        },
        {
          text: "Email",
          value: 3,
        },
      ],
      month: [],
      year: [],
    };
  },
  created() {
    this.getData();
    for (let index = 0; index < 12; index++) {
      // const element = array[index];
      this.month.push({ value: index + 1, text: index + 1 });
    }

    for (let index = 2000; index < this.$moment().year(); index++) {
      // const element = array[index];
      this.year.push({ value: index + 1, text: index + 1 });
    }
    this.year.sort((a, b) => b.value - a.value);
  },
  methods: {
    async getData() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.ChannelId += 1;
      const res = await this.$report.post("/MessaageSender", payload);
      this.items = res.data.detail.creditDetail;
      this.credit = res.data.detail.credit;
      this.isBusy = false;
    },
    async exportFile() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.ChannelId += 1;
      this.disableBtnExport = true;
      const res = await this.$report.post("/MessaageSender/export", payload, {
        responseType: "blob",
      });
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `remain-credit-` + dateExcel + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.disableBtnExport = false;
      this.isBusy = false;
    },
    clearValue() {
      this.creditAdjust.credit = "";
      this.$v.creditAdjust.$reset();
    },
    clearFilter() {
      // this.creditAdjust.credit = "";
      this.filter.Month = 0;
      this.filter.year = 0;
      this.getData();
      this.$refs.sidebarFilter.hide();
    },
    async adJustCredit(e) {
      this.$v.creditAdjust.$touch();
      if (this.$v.creditAdjust.$error) return e.preventDefault();

      const result = await this.$report.post(
        "/MessaageSender/adjust",
        this.creditAdjust
      );
      if (result.data.result) {
        this.successAlert();
        this.getData();
      } else {
        this.errorAlert(result.data.message);
      }
    },
  },
};
</script>

<style></style>
